//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionWidgets",
  components: {
    SmallTabs: () => import("@/components/common/SmallTabs"),
    Section: () => import("@/components/common/Section"),
  },
  props: {
    widgets: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: () => 'dark',
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    tabs() {
      return this?.widgets.map(item => this.$t(`${item.title}`))
    }
  }
};
